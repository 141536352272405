import { Link } from "react-router-dom";
import "./AppButton.scss";

export function AppButton(props: { children: any, h?: boolean }) {
    return <div className="container">
        <div className="button">
            <div className="button__line"></div>
            <div className="button__line"></div>
            <span className="button__text">{props.children}</span>
            {/*{props.h &&*/}
            {/*    <>*/}
            {/*        <div className="button__drow1"></div>*/}
            {/*        <div className="button__drow2"></div>*/}
            {/*    </>*/}
            {/*}*/}
        </div>
    </div>

}