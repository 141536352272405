import { useState } from "react";
import { Link } from "react-router-dom";

export function MainNav() {
    const [menuState, setMenuState] = useState("");
    return (
        <div className="header-black">
        <div className="box">
        <header className="main-header">
            <Link to="/">
                <h1 className="title">XCELERUS</h1>
            </Link>
            <button onClick={toggleMenu} className="mobile-menu-toggle">
            <span className="close material-symbols-outlined">
                menu
            </span>
            </button>
            <nav className={menuState}>
                <Link to="/#verticals">Verticals</Link>
                <Link to="/#faq">FAQs</Link>
                <Link to="/#compare">Compare</Link>
                <Link to="/#pricing">Pricing</Link>
                <Link to="/#about">About us</Link>
            </nav>
        </header>
        </div>
        </div>
    )

    function toggleMenu(){
        if(menuState == ""){
            setMenuState("open");
        }else{
            setMenuState("");
        }
    }
}