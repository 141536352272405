import { AppRotateButton } from "./RotateButton/AppRotateButton";

export function IdeaSection() {
    return (
        <section id="idea-section" className="box">
        <div className="col-2 gap-10">
          <div>
            <h3 className="w500">
              With our extensive network and deep industry knowledge, we match
              you with the perfect team to propel your business forward.
            </h3>
            <div>
              <p>
                From tech experts to creative masterminds, our diverse pool of
                talent is ready to bring fresh perspectives and innovative
                solutions to your organization. Partner with XCELERUS and
                experience a transformation in your business like never before.
              </p>
            </div>
          </div>
          <div className="center">
            <a href="/#/start/#contact-us">
              <AppRotateButton href="/start"></AppRotateButton>
            </a>
          </div>
        </div>
      </section>
    )
}