import { Link } from "react-router-dom";
import { AppRotateButton } from "./RotateButton/AppRotateButton";

export default function () {
  return (
    <>
      <section id="about" className=" about-us">
        <div className="box">
          <h2>About Us</h2>
          <p>
            Are you tired of settling for mediocre results? At XCELERUS, we
            believe that businesses can achieve exceptional performance and
            outstanding results by leveraging the talent and expertise of Sri
            Lanka
          </p>
        </div>
      </section>
    </>
  );
}
