export default function () {
  return (
    <section className="box-section box" id="verticals">
      <div className="box">
        <h3>Operations</h3>
        <h4>Driving Efficiency, Streamlining Success - Where Operations Excel!</h4>
        <div className="hashtags">
          <div>
            Program Manager
          </div>
          <div>
            Executive Assistant
          </div>
          <div>
            Supply Chain Manager
          </div>
          <div>
            Procurement Specialist
          </div>
          <div>
            Talent Acquisition Specialist
          </div>
          <div>
            Data Entry Specialist
          </div>
          <div>
            and more…
          </div>

        </div>
      </div>
      <div className="box">
        <h3>Finance</h3>
        <h4>Number Whisperers, Financial Architects - Unlocking Prosperity!</h4>
        <div className="hashtags">
          <div>
            Accountant
          </div>
          <div>
            Financial Analyst
          </div>
          <div>
            Financial Controller
          </div>
          <div>
            Tax Specialist
          </div>
          <div>
            Treasury Analyst
          </div>
          <div>
            Financial Reporting Manager
          </div>
          <div>
            and more…
          </div>
        </div>
      </div>
      <div className="box">
        <h3>Marketing</h3>
        <h4>
        Igniting Brands, Captivating Audiences - Magic Unleashed!
        </h4>
        <div className="hashtags">
          <div>
          Marketing Manager
          </div>
          <div>
          Brand Manager
          </div>
          <div>
          Digital Marketing Specialist
          </div>
          <div>
          Email Marketing Manager
          </div>
          <div>
          Content Writer/Copywriter
          </div>
          <div>
          SEO Specialist
          </div>
          <div>
          and more…
          </div>
        </div>
      </div>
      <div className="box">
        <h3>Sales</h3>
        <h4>Closing Deals, Unleashing Revenue - Where Sales Titans Thrive!</h4>
        <div className="hashtags">
          <div>Account Manager</div>
          <div>Business Development Manager</div>
          <div>Sales Representative</div>
          <div>Account Executive</div>
          <div>Sales Operations Analyst</div>
          <div>Sales Enablement Specialist</div>
          <div>and more…</div>
        </div>
      </div>
      <div className="box">
        <h3>IT</h3>
        <h4>Tech Wizards Unite, Crafting Digital Marvels - Innovation at its Finest!</h4>
        <div className="hashtags">
          <div>Software Developer</div>
          <div>System Administrator</div>
          <div>IT Project Manager</div>
          <div>Network Engineer</div>
          <div>Web Developer/Designer</div>
          <div>Database Administrator</div>
          <div>and more…</div>
        </div>
      </div>
      <div className="box">
        <h3>Customer Service</h3>
        <h4>Delighting Customers, Exceeding Expectations - Where Service Shines Bright!</h4>
        <div className="hashtags">
          <div>Customer Experience Manager</div>
          <div>Technical Support Specialist</div>
          <div>Customer Success Manager</div>
          <div>Call Center Supervisor</div>
          <div>Client Relationship Manager</div>
          <div>Customer Service Representative</div>
          <div>and more…</div>
        </div>
      </div>
    </section>
  );
}
