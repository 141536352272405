import { useState } from "react"

export function AppFAQ() {

    const [openState, setOpenState] = useState(0);

    function toggleFAQ(item: number): void {
        if (item == openState) {
            setOpenState(0);
        } else {
            setOpenState(item);
        }
    }

    return <section id="faq" className="faq">
        <div className="box">
            <h2>FAQs</h2>
        </div>
        <div>
            <section className="faq-box-section box">
                <div className={openState == 1 ? "open-wrapper box" : "box"}>
                    <h4 className={openState == 1 ? "active" : ""} onClick={() => { toggleFAQ(1) }}>Why should we consider hiring candidates from Sri Lanka?
                        <span className="close material-symbols-outlined">
                            chevron_right
                        </span>
                        <span className="open material-symbols-outlined">
                            expand_more
                        </span></h4>
                    {openState == 1 && <p>
                        Hiring candidates from Sri Lanka offers several advantages. Sri Lanka has a diverse talent pool with a strong educational system, producing skilled professionals in various fields. Additionally, Sri Lankan professionals often possess a high level of proficiency in English, making communication seamless. Almost all of the talent we pair will have years of experience working for multinational companies and at least one major qualification from a US/UK university.
                    </p>}
                </div>
                <div className={openState == 2 ? "open-wrapper box" : "box"}>
                    <h4 className={openState == 2 ? "active" : ""} onClick={() => { toggleFAQ(2) }}>
                        What is your screening process for candidates?<span className="close material-symbols-outlined">
                            chevron_right
                        </span>
                        <span className="open material-symbols-outlined">
                            expand_more
                        </span></h4>
                    {openState == 2 && <p>
                        We have a rigorous screening process that includes evaluating resumes, conducting initial interviews, skills assessments, and reference checks. We ensure that candidates align with your requirements before presenting them to you.
                    </p>}
                </div>
                <div className={openState == 3 ? "open-wrapper box" : "box"}>
                    <h4 className={openState == 3 ? "active" : ""} onClick={() => { toggleFAQ(3) }}>
                        What is your approach to confidentiality and data protection?
                        <span className="close material-symbols-outlined">
                            chevron_right
                        </span>
                        <span className="open material-symbols-outlined">
                            expand_more
                        </span></h4>
                    {openState == 3 && <p>
                        Confidentiality and data protection are paramount to us. We have robust measures to ensure the security and privacy of all client information and strictly adhere to applicable data protection regulations.
                    </p>}
                </div>
                <div className={openState == 4 ? "open-wrapper box" : "box"}>
                    <h4 className={openState == 4 ? "active" : ""} onClick={() => { toggleFAQ(4) }}>
                    Can we provide input on the type of candidates we are looking for?
                        <span className="close material-symbols-outlined">
                        chevron_right
                    </span>
                        <span className="open material-symbols-outlined">
                            expand_more
                        </span></h4>
                    {openState == 4 && <p>
                        Absolutely! We value your input and understand the importance of finding candidates who align with your company culture and specific requirements. We welcome your insights to tailor our search and ensure we find the best match for your needs.
                    </p>}
                </div>
                <div className={openState == 5 ? "open-wrapper box" : "box"}>
                    <h4 className={openState == 5 ? "active" : ""} onClick={() => { toggleFAQ(5) }}>
                    What happens if we are not satisfied with a candidate you've presented?
                        <span className="close material-symbols-outlined">
                        chevron_right
                    </span>
                        <span className="open material-symbols-outlined">
                            expand_more
                        </span></h4>
                    {openState == 5 && <p>
                        We understand that a perfect match is not always guaranteed. In such cases, we work closely with you to understand your feedback and refine our search criteria. We are committed to finding the right candidate who meets your needs.
                    </p>}
                </div>
                <div className={openState == 6 ? "open-wrapper box" : "box"}>
                    <h4 className={openState == 6 ? "active" : ""} onClick={() => { toggleFAQ(6) }}>
                    What is your average timeframe for filling a job position?
                        <span className="close material-symbols-outlined">
                            chevron_right
                        </span>
                        <span className="open material-symbols-outlined">
                            expand_more
                        </span>
                    </h4>
                    {openState == 6 && <p>
                        The timeframe for filling a job position can vary depending on the complexity of the role, the availability of qualified candidates, and the specific requirements provided. However, we strive to work efficiently to present you with top candidates in a timely manner, this can usually range anywhere between a week to 3 weeks tops.
                    </p>}
                </div>
                <div className={openState == 7 ? "open-wrapper box" : "box"}>
                    <h4 className={openState == 7 ? "active" : ""} onClick={() => { toggleFAQ(7) }}>
                    What are your fees and payment terms?
                        <span className="close material-symbols-outlined">
                            chevron_right
                        </span>
                        <span className="open material-symbols-outlined">
                            expand_more
                        </span>
                    </h4>
                    {openState == 7 && <p>
                        Our fees and payment terms may vary depending on the nature and complexity of the recruitment project. We will provide you with transparent details regarding our fees, payment terms, and any additional costs during the initial discussions.
                    </p>}
                </div>
            </section>
        </div>
    </section>
}