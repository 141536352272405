import { AppHome } from "./AppHome";
import {
    RouterProvider,
    createHashRouter,
    useLocation,
} from "react-router-dom";
import { Start } from "./Start";

const router = createHashRouter([
    {
        path: "/",
        element: <AppHome />,
    },
    {
        path: "start",
        element: <Start />,
    },
    {
        path: "*",
        element: <AppHome />,
    },
]);

function App() {

    return <RouterProvider router={router} />
}

export default App;
