export default function(){
    return (
        <section id="compare">
        <div className="box">
          <h2 className="title">COMPARE</h2>
          <div className="other-us">
            <div className="other">
              <h3>Others In the Space</h3>
              <ul>
                <li>Limited Local Insight</li>
                <li>Generic Cultural Considerations</li>
                <li>Communication Gaps</li>
                <li>Conventional Recruitment Methods</li>
                <li>Limited Industry Specialization</li>
                <li>Limited Post-Placement Engagement</li>
                <li>Heavy Upfront Investment</li>
                <li>No Career Progression or Skill Upgrades</li>
              </ul>
            </div>
            <div className="us">
              <h3>XCELERUS</h3>
              <ul>
                <li>Deep Understanding of Local Talent </li>
                <li>Ensures Cultural Fit</li>
                <li>Responsive and Transparent Communication</li>
                <li>In-built Talent Pool</li>
                <li>Diverse Industry Experience</li>
                <li>Post-Placement Engagement & Support</li>
                <li>Flexible Fee Structure</li>
                <li>Emphasis on Continuous Development</li>
              </ul>
            </div>
            <div className="vs">VS</div>
          </div>
        </div>
      </section>
    )
}