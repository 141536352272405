//@ts-ignore
import { AppButton } from "./button/AppButton";
import { AppFAQ } from "./FAQ";
import "./App.scss";
import { Link, useLocation } from "react-router-dom";
import { MainNav } from "./MainNav";
import Compare from "./Compare";
import Services from "./Services";
import Pricing from "./Pricing";
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import { useEffect } from "react";
import video1 from "./imgs/video1.mp4";
import { IdeaSection } from "./IdeaSection";

export function AppHome() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 100);
    }
  }, [pathname, hash, key]); // do this on route change

  useEffect(() => {
    try {
      (document as any).querySelector("video").playbackRate = 0.9;
      (document as any)
        .querySelector("video")
        .addEventListener("timeupdate", function (this: HTMLVideoElement) {
          console.log("video play", this.currentTime);
          if (this.currentTime >= 4) {
            (document as any).querySelector("video").playbackRate = 0.7;
          }
          if (this.currentTime >= 4.5) {
            (document as any).querySelector("video").playbackRate = 0.3;
          }
          if (this.currentTime >= 5.1) {
            this.pause();
          }
        });
    } catch (error) {}
  }, []);

  return (
    <>
      <div className="home">
        <MainNav />
        <main>
          <div className="first-section-color">
            <video
              className="firstVideo"
              autoPlay={true}
              muted
              playsInline={true}
              loop
              id="video background"
            >
              <source src={video1 as any} type="video/mp4" />
            </video>
            <section className="first-section box">
              <div className="first-text-width">
                <h1>
                  We are a leading business accelerator, dedicated to connecting
                  clients with the best talent from Sri Lanka.
                </h1>
                <h2>
                  Elevate your performance, exceed your goals, and unlock your
                  full potential with XCELERUS.
                </h2>
              </div>
              <div className="btn">
                <Link to="/start">
                  <AppButton h={true}>Let's get started</AppButton>
                </Link>
              </div>
            </section>
            <Services />
          </div>
          <AppFAQ />
          <Compare />
          <div className="pricing-bg">
            <Pricing />
            <AboutUs />
          </div>
          <div className="idea-bg">
            <IdeaSection />
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}
