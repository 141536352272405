//@ts-ignore
import { AppButton } from "./button/AppButton";
import { AppFAQ } from "./FAQ";
import "./App.scss";
import { MainNav } from "./MainNav";
import Compare from "./Compare";
import Services from "./Services";
import Footer from "./Footer";
import Pricing from "./Pricing";
import { useEffect, useState } from "react";

export function Start() {
  const [email, setEmail] = useState("");

  useEffect(() =>{
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <div className="start" id="start">
        <MainNav />
        <main>
          <div className="email-bg">
            <section id="contact-us" className="email-box box">
              <div className="input-wrap">
                <div></div>
                <input
                  type="email"
                  onChange={(email) => {
                    setEmail(email.target.value);
                  }}
                  placeholder="Type Your Email"
                  className="txt-email"
                />
              </div>
              <div className="btn">
                <a
                  href={
                    "https://calendly.com/navin-xcelerus/30min?email=" + email
                  }
                  target="__blank"
                >
                  <AppButton>Next</AppButton>
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
      <Compare />
      <div className="pricing-bg">
        <Pricing />
      </div>
      <AppFAQ />
      <Footer />
    </>
  );
}
