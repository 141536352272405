export default function(){
    return (
        <footer>
        <div className="footer-text-wrap">
          <div>
            <h1>XCELERUS</h1>
            <div>helping businesses reach their full potential</div>
          </div>
          <div>
            {/* <p className="social-txt">Social</p>
            <div style={{ display: "flex" }}>
              <p>Facebook &nbsp;|</p>
              <p>&nbsp;Twitter</p>
            </div> */}
            <p>
              <small>
                &copy; Copyright 2024 |{" "}
                <a href="https://xcelerus.com">xcelerus.com</a>
              </small>
            </p>
          </div>
        </div>
        <div className="site-map"></div>
      </footer>
    )
}