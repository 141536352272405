import { useEffect } from "react";
import { Link } from "react-router-dom";
import { AppButton } from "./button/AppButton";

export default function () {
  useEffect(() => {
    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  return (
    <section id="pricing" className="pricing box">
      <h2 className="title">PRICING</h2>
      <div className="p-box-wrap">
        <div>
          <h2>Bronze Tier </h2>
          <div className="pricing">starting at $1200/month</div>
          <ul>
            <li>
              Cost-effective solution: This tier offers competitive pricing
              without compromising on quality. Think lower level management with
              tons of potential.{" "}
            </li>
            <li>
              Skillful professionals: Access a pool of talented professionals
              who excel in their respective fields.
            </li>
            <li>
              Reliable support: Benefit from dependable support and timely
              project delivery.
            </li>
          </ul>
        </div>
        <div>
          <h2>Silver Tier</h2>
          <div className="pricing">starting at $2400/month</div>
          <ul>
            <li>
              Enhanced expertise: Access a higher level of expertise with
              professionals possessing advanced skills and experience. These are
              your Middle Managers ready to act with tact.
            </li>
            <li>
              Proactive approach: Enjoy proactive communication and personalized
              attention, ensuring your project's success.
            </li>
            <li>
              Dedicated account manager: Get assigned a dedicated account
              manager to oversee your hire’s progress and working relationship.
            </li>
          </ul>
        </div>
        <div>
          <h2>Gold Tier</h2>
          <div className="pricing">starting at $4800/month</div>
          <ul>
            <li>
              Elite talent: Access the cream of the crop, with highly
              experienced professionals possessing exceptional skills. Talent on
              par with top management.{" "}
            </li>
            <li>
              Tailored solutions: Receive customized solutions, designed
              specifically to meet your unique business requirements.
            </li>
            <li>
              Priority support: Enjoy priority support and fast response times
              for any queries or concerns.
            </li>
            <li>
              Strategic guidance: Benefit from expert guidance and strategic
              insights to optimize your business's performance and results.
            </li>
          </ul>
        </div>
      </div>
      <p style={{fontSize:15, color: 'black'}}>
        <i className="small ">
          These tiers can be adjusted and customized according to your specific
          needs and preferences and our Enterprise solution can be very
          attractive if you’re looking to outsource an entire department or
          hiring for multiple roles.
        </i>
      </p>
      <a href="/#/start/#contact-us">
        <AppButton h={true}>Find out more.</AppButton>
      </a>
    </section>
  );
}
